import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  coupons: [],
  couponList: [],
  couponTemplateList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCouponsSuccess(state, action) {
      state.isLoading = false;
      state.coupons = action.payload;
    },

    deleteCoupon(state, action) {
      const deleteCoupon = filter(state.couponList, (coupon) => coupon._id !== action.payload);
      console.log(deleteCoupon);
      state.couponList = deleteCoupon;
    },

    deleteCouponTemplate(state, action) {
      const deleteCoupon = filter(state.couponTemplateList, (coupon) => coupon._id !== action.payload);
      state.couponTemplateList = deleteCoupon;
    },

    getCouponListSuccess(state, action) {
      state.isLoading = false;
      state.couponList = action.payload;
    },

    getCouponsFinancialReport(state, action) {
      state.isLoading = false;
      state.couponsFinancialReport = action.payload;
    },

    getCouponTemplateListSuccess(state, action) {
      state.isLoading = false;
      state.couponTemplateList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCouponList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/coupons`);
      dispatch(slice.actions.getCouponListSuccess(response.data.coupons));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCouponsFinancialReport({start, end}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/coupons/financialReport?start=${start}&end=${end}`);
      dispatch(slice.actions.getCouponsFinancialReport(response.data.usedCoupons));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCouponTemplateList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/coupons-templates`);
      dispatch(slice.actions.getCouponTemplateListSuccess(response.data.coupons));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addCouponByTemplate(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/coupons-templates`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function storeCoupon(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/coupons`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateCoupon(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/coupons`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCoupon(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/coupons/${newId}`);
      dispatch(slice.actions.deleteCoupon(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function deleteCouponTemplate(couponId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/coupons/${couponId}`);
      dispatch(slice.actions.deleteCouponTemplate(couponId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
