import sumBy from 'lodash/sumBy';
import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import DatePicker from '@material-ui/lab/DatePicker';
import {
  Card,
  Box,
  Table,
  TableRow,
  TextField,
  Button,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Tabs,
  Tab,
  Badge,
  Divider,
  Stack,
  styled,
} from '@material-ui/core';
import { useSnackbar } from 'notistack5';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable, { getComparator } from '../../../hooks/useTable';
import { getCouponsFinancialReport } from '../../../redux/slices/coupon';
import { UserListHead } from '../../../components/_dashboard/user/list';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { TableNoData, TableSkeleton } from '../../../components/table';
import { useDispatch, useSelector } from '../../../redux/store';
import ReportAnalytic from '../../../components/_dashboard/report/ReportAnalytic';
import { fMoney } from '../../../utils/formatNumber';

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', align: 'left' },
  { id: 'responsible', label: 'Responsável', align: 'left' },
  { id: 'totalDiscountValue', label: 'Total descontado', align: 'left' },
  { id: 'totalUsageTimes', label: 'Total de usos', align: 'left' },
];

export default function CouponsFinancialReport() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isHovered, setHovered] = useState(null);

  const couponResponsibleHandle = (responsible) => {
    switch (responsible) {
      case 'jao': return 'Jão'
      case 'licensed': return 'Licenciado'
      case 'mixed': return 'Dividido'
      default: return '-'
    }
  }

  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { couponsFinancialReport, isLoading } = useSelector((state) => state.coupon);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({ defaultOrderBy: 'createDate' });

  let tableData = couponsFinancialReport || [];
  const [filterStartDate, setFilterStartDate] = useState(`${dayjs().format('YYYY-MM')}-01`);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [orderStatus, setOrderStatus] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');

  useEffect(() => {
    const end = new Date();
    end.setDate(end.getDate() + 1);
    const endISO = end.toISOString().slice(0, 10);

    setFilterEndDate(endISO);
    dispatch(getCouponsFinancialReport({ start: filterStartDate, end: endISO }));
  }, [dispatch]);

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterStatus,
    filterStartDate,
    filterEndDate
  });

  const denseHeight = dense ? 56 : 76;

  const isNotFound =
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate) ||
    (!isLoading && !dataFiltered.length);

  const filterDate = async () => {
    if (filterStartDate && filterEndDate) {
      const start = dayjs(filterStartDate).format('YYYY-MM-DD');
      const end = new Date(filterEndDate).toISOString().slice(0, 10);
      dispatch(getCouponsFinancialReport({ start, end }));
    }
  };

  const handleMouseEnter = (id) => {
    setHovered(id);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const TABS = [
    { value: 'all', label: 'Todos', color: 'info', count: tableData.length },
    { value: 'licensed', label: 'Licenciado', color: 'success', count: couponsFinancialReport?.filter(c => c.responsible === 'licensed').length },
    { value: 'jao', label: 'Jão', color: 'warning', count: couponsFinancialReport?.filter(c => c.responsible === 'jao').length },
    { value: 'mixed', label: 'Dividido', color: 'secondary', count: couponsFinancialReport?.filter(c => c.responsible === 'mixed').length },
  ];
  const initSlice = page * rowsPerPage
  if (orderStatus !== 'all') tableData = tableData.filter(c => c.responsible === orderStatus)

  const licensedTotalValue = sumBy(couponsFinancialReport?.filter(c => c.responsible === 'licensed'), 'totalDiscountValue')
  const jaoTotalValue = sumBy(couponsFinancialReport?.filter(c => c.responsible === 'jao'), 'totalDiscountValue')

  const mixedTotalValue = sumBy(couponsFinancialReport?.filter(c => c.responsible === 'mixed'), 'totalDiscountValue')
  return (
    <Page title="Relatório Financeiro | Cupons">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Relatório Financeiro - Cupons"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Relatório Financeiro - Cupons', href: PATH_DASHBOARD.financial.coupons }
          ]}
        />

        <Scrollbar>
          <Stack
            direction="row"
          >
            <ReportAnalytic
              text="cupons"
              title="Total"
              percent={100}
              total={couponsFinancialReport?.length}
              price={sumBy(couponsFinancialReport, 'totalDiscountValue')}
              icon="ic:round-receipt"
              color={theme.palette.info.main}
            />
            <ReportAnalytic
              title="Licenciado"
              total={couponsFinancialReport?.filter(c => c.responsible === 'licensed').length}
              price={licensedTotalValue}
              icon="ic:round-receipt"
              text="cupons"
              color={theme.palette.success.main}
            />
            <ReportAnalytic
              title="Jão"
              total={couponsFinancialReport?.filter(c => c.responsible === 'jao').length}
              price={jaoTotalValue}
              icon="ic:round-receipt"
              text="cupons"
              color={theme.palette.warning.main}
            />
            <ReportAnalytic
              title="Dividido"
              total={couponsFinancialReport?.filter(c => c.responsible === 'mixed').length}
              price={mixedTotalValue}
              icon="ic:round-receipt"
              text="cupons"
              color={theme.palette.secondary.main}
            />
          </Stack>
        </Scrollbar>
        <br />

        <Stack
          direction="row"

        >
          <ReportAnalytic
            title="A receber"
            price={jaoTotalValue + (mixedTotalValue / 2)}
            total={couponsFinancialReport?.filter(c => c.responsible === 'jao').length}
            icon="ic:round-attach-money"
            text="cupons"
            percent={100}
            color={theme.palette.success.main}
          />
          <ReportAnalytic
            title="A pagar"
            percent={100}
            total={couponsFinancialReport?.filter(c => c.responsible === 'licensed').length}
            price={licensedTotalValue + (mixedTotalValue / 2)}
            icon="ic:round-attach-money"
            text="cupons"
            color={theme.palette.error.main}
          />
        </Stack>
        <br />
        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                onClick={() => setOrderStatus(tab.value)}
                label={
                  <Stack spacing={1} direction="row" alignItems="center">
                    <div>{tab.label}</div> <Label color={tab.color}> {tab.count} </Label>
                  </Stack>
                }
              />
            ))}
          </Tabs>

          <Divider />

          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div
              style={{ display: 'flex', gap: '1em' }}
            >
              <DatePicker
                label="Data início"
                value={dayjs(filterStartDate)?.toISOString()}
                onChange={(newValue) => {
                  setFilterStartDate(newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "10em" }}
                  />
                )}
              />
              <DatePicker
                label="Data fim"
                value={filterEndDate}
                onChange={(newValue) => {
                  setFilterEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "10em" }}
                  />
                )}
              />
            </div>
            <Button onClick={async () => {
              await filterDate();
            }}>Filtrar</Button>
          </Stack>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <UserListHead
                  sx={{ width: "5em", minWidth: "1em" }}
                  headLabel={TABLE_HEAD}
                  rowCount={123}
                />
                <TableBody>
                  {tableData?.slice(initSlice, initSlice + rowsPerPage)?.map((row, index) =>
                    row ? (
                      <TableRow
                        className={isHovered === row?.id ? 'divOnHover' : ''}
                        onMouseEnter={() => {
                          handleMouseEnter(row?.id);
                        }}
                        onMouseLeave={handleMouseLeave}
                        style={{ cursor: 'pointer' }}
                        key={row.id}
                      >
                        <TableCell>
                          {row?.name}
                        </TableCell>
                        <TableCell>
                          {couponResponsibleHandle(row?.responsible)}
                        </TableCell>
                        <TableCell>
                          R$ {fMoney(row?.totalDiscountValue || 0)}
                        </TableCell>
                        <TableCell>
                          {row?.totalUsageTimes}
                        </TableCell>
                      </TableRow>
                    ) : (
                      !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    )
                  )}
                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => `Registros ${from}-${to} do total de ${count}`}
              labelRowsPerPage="Registros por página"
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

function applySortFilter({ tableData, comparator, filterStatus }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  return tableData;
}
