import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
import serialize from '../../utils/serialize';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  restaurants: [],
  restaurantInfo: [],
  restaurantList: [],
  ratingList: [],
  brothersList: [],
  cartsAbandonned: [],
  restaurant: {},
  responsePost: null,
  info: [],
  infoRequestYear: [],
  infoRequestMonth: [],
  restaurantRequests: null
};

const slice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {

      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET RESTAURANTS
    getRestaurantsSuccess(state, action) {
      state.isLoading = false;
      state.restaurants = action.payload;
    },

    // DELETE RESTAURANTS
    deleteRestaurant(state, action) {
      const deleteRestaurant = filter(state.restaurantList, (restaurant) => restaurant._id !== action.payload);
      state.restaurantList = deleteRestaurant;
    },

    updateRestaurantReload(state, action) {
      const newState = state.restaurantList.map((obj) => {
        if (obj._id === action.payload._id) {
          return { ...obj, ...action.payload };
        }

        return obj;
      });
      state.restaurantList = newState;
    },

    // GET MANAGE RESTAURANTS
    getRestaurantListSuccess(state, action) {
      state.isLoading = false;
      state.restaurantList = action.payload;
    },

    getInfoOrdersSuccess(state, action) {
      state.isLoading = false;
      state.infoOrders = action.payload;
    },

    getRatingListSuccess(state, action) {
      state.isLoading = false;
      state.ratingList = action.payload;
    },

    // GET RESTAURANT
    getRestaurantSuccess(state, action) {
      state.isLoading = false;
      state.restaurant = action.payload;
    },

    getCartsAbandonnedSuccess(state, action) {
      state.isLoading = false;
      state.cartsAbandonned = action.payload;
    },

    getRequestsSuccess(state, action) {
      state.isLoading = false;
      state.restaurantRequests = action.payload;
    },

    getInfoSuccess(state, action) {
      state.isLoading = false;
      state.info = action.payload;
    },

    getInfoRequestYearSuccess(state, action) {
      state.isLoading = false;
      state.infoRequestYear = action.payload;
    },

    getInfoRequestMonthSuccess(state, action) {
      state.isLoading = false;
      state.infoRequestMonth = action.payload;
    },

    getRestaurantInfoSuccess(state, action) {
      state.isLoading = false;
      state.restaurantInfo = action.payload;
    },

    getBrothersSuccess(state, action) {
      state.isLoading = false;
      state.brothersList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getRestaurantList(page = 1, limit = 10, name = '', admin = false) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get(`${api}/restaurants?page=${page}&limit=${limit}&name=${name}&admin=${admin}`);
      dispatch(slice.actions.getRestaurantListSuccess(response.data.restaurants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInfoOrders(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-orders?${data}`);
      dispatch(slice.actions.getInfoOrdersSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getRestaurantInfo() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get(`${api}/restaurants`);
      dispatch(slice.actions.getRestaurantInfoSuccess(response.data.restaurants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function storeRestaurantUnauth(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(data)
      const response = await axios.post(`${api}/restaurants/unauth`, data);
      // dispatch(slice.actions.getRestaurantListSuccess(response.data.restaurants));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      return error.response.data;
    }
  };
}

export function storeRestaurant(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/restaurants`, data, {
        headers: {
          'Info-Header': data.get('local[city]')
        }
      });
      // dispatch(slice.actions.getRestaurantListSuccess(response.data.restaurants));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateEcommerce(data) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${api}/restaurants-ecommerce`, data);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateRestaurant2(data, city, author) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${api}/restaurants`, data, {
        headers: {
          'Info-Header': city,
          'Author': author 
        }
      });
      dispatch(slice.actions.updateRestaurantReload(data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function updateRestaurant(data) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${api}/restaurants`, data, {
        headers: {
          'Info-Header': data.get('local[city]')
        }
      });
      return response.data;
    } catch (error) {

      return error.response.data;
    }
  };
}

export function importIfood(rest, id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${api}/ifood/${rest}/${id}`);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function updateRestaurantShopkeeper(data) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/restaurant`, data);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteRestaurant(newId, city) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/restaurants/${newId}`, {
        headers: {
          'Info-Header': city
        }
      });
      dispatch(slice.actions.deleteRestaurant(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getBrothers(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/restaurants-brothers/${id}`);
      dispatch(slice.actions.getBrothersSuccess(response.data.brothers.restaurants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getRestaurant() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/restaurant`);
      dispatch(slice.actions.getRestaurantSuccess(response.data.restaurant));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCartAbandonned(city) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading()); 
    try {
      const response = await axios.get(`${api}/carts/abandonned/${city}`);
      dispatch(slice.actions.getCartsAbandonnedSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getSound() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${api}/request-sound`);
      dispatch(slice.actions.getRequestsSuccess(response.data.requests));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInfo(data) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${api}/info-dashboard`, data);
      dispatch(slice.actions.getInfoSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoRequestYear(data) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${api}/info-request-year`, data);
      dispatch(slice.actions.getInfoRequestYearSuccess(response.data.result));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function getInfoRequestMonth(data) {
  return async (dispatch) => {
    try {
      data = serialize(data);
      const response = await axios.get(`${api}/info-request-month?${data}`);
      dispatch(slice.actions.getInfoRequestMonthSuccess(response.data.results));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      return error.response.data;
    }
  };
}

export function getRatingList(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${api}/ratings?id=${id}`);
      dispatch(slice.actions.getRatingListSuccess(response.data.ratings));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}
