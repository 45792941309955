const serialize = (obj, page = false) => {
  const str = [];
  if (!Object.prototype.hasOwnProperty.call(obj, page)) {
    obj.page = 1;
  }
  Object.keys(obj).forEach((p) => {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      if (p === 'page' && page) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(page)}`);
      } else {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
      }
    }
  });
  return str.join('&');
};

export default serialize;
