import React from 'react';
import useSettings from '../hooks/useSettings';

export default function SettingMode({ width = '120px' }) {
  const { themeMode } = useSettings();
  return (
    <img
      alt="Logo Jão Delivery"
      src={`/static/logo/${themeMode === 'dark' ? 'logo.png' : 'logoDark.png'}`}
      width={width}
    />
  );
}
