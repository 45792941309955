import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';

import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  users: [],
  municipios: [],
  userList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'licensed',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET MUNICIPIOS
    getMunicipiosSuccess(state, action) {
      state.isLoading = false;
      state.municipiosList = action.payload;
    },

    // GET MUNICIPIO
    getMunicipioSuccess(state, action) {
      state.isLoading = false;
      state.municipioList = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getUserList(userType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = userType ? `?userType=${userType}` : '';
      const response = await axios.get(`${api}/licenseds${query}`);
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeLicensed(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/licenseds`, values);
      // dispatch(slice.actions.getUserListSuccess(response.data.users));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateLicensed(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/users`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteLicensed(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/licenseds/${newId}`);
      dispatch(slice.actions.deleteUser(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function getMunicipios() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/municipios-panel`);
      dispatch(slice.actions.getMunicipiosSuccess(response.data.municipios.docs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMunicipio(uf, ibge) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/municipios/${uf}/${ibge}`);
      dispatch(slice.actions.getMunicipioSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
