export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          '&:last-of-type': {
            borderRight: 'none',
          },
          padding: theme.spacing(2)
        },
        head: {
          color: 'white',
          backgroundColor: '#262626',
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
          }
        },
        stickyHeader: {
          backgroundColor: '#262626', // Fundo do cabeçalho sticky preto
          color: 'white', // Cor do texto do cabeçalho sticky branco
          backgroundImage: 'none', // Remover o gradiente
        },
        body: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(2)
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3)
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}
