// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Typography, Grid } from '@material-ui/core';

import { useEffect } from 'react';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import { LoginForm } from '../../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
  console.log(params.authToken);
  if (params.authToken) {
    localStorage.setItem('accessToken', params.authToken);
    localStorage.setItem('soundNotification', '0');
    window.location.replace('/dashboard/request/list');
  }
  return (
    <RootStyle title="Login | Jão Delivery">
      <Grid
        item
        spacing={0}
        md={6}
        style={{ backgroundBlendMode: "darken", background: 'rgba(0,0,0,0.2) url(/static/login/bg.jpg)', backgroundPosition: 'center', backgroundSize: 'cover' }}
      />
      <img style={{ position: "absolute", top: "25%", width: "40vw", maxWidth: "500px", marginLeft: "3em" }} alt="" src="/static/login/text.png" />
      <Grid item spacing={0} md={6} sx={{ pl: 3, pr: 3, background: "white", position: "relative" }}>
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <div style={{ width: '150px', margin: '0 auto 15px auto' }}>
                <Logo width="150px" />
              </div>
            </Box>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Grid>
    </RootStyle>
  );
}
