import { Font, StyleSheet } from '@react-pdf/renderer';

// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }]
});

const styles = StyleSheet.create({
  image: { width: '100px', height: 'auto', alignSelf: 'center', marginBottom: '10px', filter: 'brightness(0%)' },
  qrcode: { width: '80px', height: 'auto', alignSelf: 'center', marginBottom: '10px' },
  center: { textAlign: 'center' },
  bold: { fontWeight: 'bold' },
  page: {
    padding: '10px 15px 10px 15px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff',
  },
  space: {
    borderTop: '1px dashed black',
    marginTop: '10px',
    paddingTop: '10px',
    height: 'auto'
  },
  mini: {
    fontSize: 8
  }
});

export default styles;
