import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fMoney(number) {
  if (number === undefined || Number.isNaN(number)) number = 0;
  number = parseFloat(number).toFixed(2);
  return Number(number).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
}

export const handleMoneyValue = (event) => {
  const number = event?.target?.value || event.toString()
  if (!number) return '00.00';
  const onlyDigits = number
    .split('')
    .filter((s) => /\d/.test(s))
    .join('')
    .padStart(3, '0');
  // eslint-disable-next-line
  const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
  return handleZero(digitsFloat);
};

const handleZero = (number) => {
  const array = number.split('');
  const mark = array.indexOf('.');
  if (mark <= 1) return number;
  let nonZeroIndex = -1;
  // eslint-disable-next-line
  for (let i = 0; i < mark; i++) {
    if (array[i] !== '0') {
      nonZeroIndex = i;
      break;
    }
  }
  if (mark > 2 && nonZeroIndex < 0) return number.substring(mark - 2);

  return nonZeroIndex >= 1 ? number.substring(nonZeroIndex) : number;
};



