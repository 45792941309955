import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  districts: [],
  districtList: [],
  citiesList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'district',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDistrictsSuccess(state, action) {
      state.isLoading = false;
      state.districts = action.payload;
    },

    deleteDistrict(state, action) {
      const deleteDistrict = filter(state.districtList, (district) => district._id !== action.payload);
      console.log(deleteDistrict);
      state.districtList = deleteDistrict;
    },

    getDistrictListSuccess(state, action) {
      state.isLoading = false;
      state.districtList = action.payload;
    },

    getCitiesListSuccess(state, action) {
      state.isLoading = false;
      state.citiesList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getDistrictList(city = '') {
  let filter = '';
  if (city) filter = `?city=${city}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/districts${filter}`);
      dispatch(slice.actions.getDistrictListSuccess(response.data.districts.docs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeDistrict(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/districts`, values, {
        headers: {
          'Info-Header': values.data[0].codigo_ibge
        }
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateDistrict(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/districts`, values, {
        headers: {
          'Info-Header': values.city
        }
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDistrict(newId, idCity) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/districts/${newId}`, {
        headers: {
          'Info-Header': idCity
        }
      });
      dispatch(slice.actions.deleteDistrict(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function getAllCitys() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/locationsAll`);
      dispatch(slice.actions.getCitiesListSuccess(response.data.municipios));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response?.data;
    }
  };
}
