import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import serialize from '../../utils/serialize';

import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  topLicensed: [],
  responsePost: null
};

const slice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTopLicensedSuccess(state, action) {
      state.isLoading = false;
      state.topLicensed = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getTopLicensedList(query = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      query = serialize(query);
      const response = await axios.get(`${api}/insights/top-licenseds?${query}`);
      dispatch(slice.actions.getTopLicensedSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}