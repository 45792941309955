import { useSnackbar } from 'notistack5';
import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
import serialize from '../../utils/serialize';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  requestList: [],
  requestListNumbers: [],
  allRequestList: [],
  requestsReport: {}
};

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getRequestListSuccess(state, action) {
      state.requestList = action.payload;
      state.isLoading = false;
    },

    getAllRequestListSuccess(state, action) {
      state.allRequestList = action.payload;
      state.isLoading = false;
    },

    getRequestListSuccessNumbers(state, action) {
      state.requestListNumbers = action.payload;
      state.isLoading = false;
    },

    getRequestReportSuccess(state, action) {
      state.isLoading = false;
      state.requestsReport = action.payload;
    },
    deleteRequest(state, action) {
      const deleteRequest = filter(state.requestList, (request) => request._id !== action.payload);
      state.requestList = deleteRequest;
    }

  }
});

// Reducer
export default slice.reducer;

export function getRequestList(page = 1, limit = 20, name = '', status = 'all', period = 'all') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/requests?page=${page}&limit=${limit}&name=${name}&status=all&period=${period}`);
      dispatch(slice.actions.getRequestListSuccess(response.data.requests));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } 
  };
}

export function getRequestListNumbers(page = 1, limit = 20, name = '', status = 'all', period = 'all') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/requests?page=${page}&limit=${limit}&name=${name}&status=${status}&period=${period}`);
      dispatch(slice.actions.getRequestListSuccessNumbers(response.data.requests));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllRequestList(page = 1, limit = 20, name = '', status = 'all', period = 'all') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/requests-all?page=${page}&limit=${limit}&name=${name}&status=${status}&period=${period}`);
      dispatch(slice.actions.getAllRequestListSuccess(response.data.requests));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRequestsReportList(options = '') {
  if (options) {
    options = `?${serialize(options)}`;
  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/requestsReport${options}`);
      dispatch(slice.actions.getRequestReportSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function updateRequest(values, brothers = false, status) {
  return async (dispatch, getState) => {
    const state = getState();

    if (state.request.isLoading === true) {
      dispatch(slice.actions.stopLoading());
      return;
    }
    dispatch(slice.actions.startLoading());
    try { 
      let response2;
      const response = await axios.put(`${api}/requests`, values);

      if (brothers) {
        response2 = await axios.get(`${api}/requests-all?limit=1000`);
        dispatch(slice.actions.getAllRequestListSuccess(response2.data.requests));
      } else {
        response2 = await axios.get(`${api}/requests?page=1&limit=30&name=&status=${status}&period=all`);
        dispatch(slice.actions.getRequestListSuccess(response2.data.requests));

      }

      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteRequest(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/requests/${newId}`);
      dispatch(slice.actions.deleteRequest(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

}


export function reativeRequest(newId, brothers = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let response2
    try {
      await axios.put(`${api}/requests-reative/${newId}`);
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

}

