// routes
import { Icon } from '@iconify/react';
// components
import Label from '../../components/Label';
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  cart: getIcon('ic_cart'),
  user: getIcon('ic_user'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'geral',
    items: [
      {
        title: 'início',
        type: [1, 2],
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'início',
        type: [5, 6],
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'início',
        type: [3, 4],
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'clientes',
        type: [1, 2, 3, 4],
        path: PATH_DASHBOARD.customer.list,
        icon: <Icon width="22" height="22" icon="clarity:users-solid" />
      },
      {
        title: 'estabelecimentos',
        path: PATH_DASHBOARD.restaurant.admin,
        icon: <Icon icon="fa6-solid:house-laptop" width="22" />,
        type: [1, 2]
      },
      {
        title: 'Pedidos',
        path: PATH_DASHBOARD.request.list,
        icon: <Icon icon="uil:invoice" width="22" height="22" />,
        type: [5, 6],
        children: [
          { title: 'pedidos de hoje', path: PATH_DASHBOARD.request.list },
          { title: 'pedidos integrados', path: PATH_DASHBOARD.request.listAll },
          { title: 'histórico de pedidos', path: PATH_DASHBOARD.report.history }
        ]
      },
      {
        title: 'Pedidos',
        path: PATH_DASHBOARD.request.list,
        icon: <Icon icon="uil:invoice" width="22" height="22" />,
        type: [1, 2, 3, 4],
        children: [
          { title: 'pedidos de hoje', path: PATH_DASHBOARD.request.list },
          { title: 'histórico de pedidos', path: PATH_DASHBOARD.report.history }
        ]
      },
      {
        title: 'Pagamentos',
        path: PATH_DASHBOARD.payment.root,
        icon: <Icon icon="fluent:payment-16-regular" width="22" height="22" />,
        type: [3, 5],
        children: [
          { title: 'Minha conta', path: PATH_DASHBOARD.payment.root },
          { title: 'Extrato', path: PATH_DASHBOARD.payment.history },
          { title: 'Ajustes', path: PATH_DASHBOARD.payment.online }
        ]
      },
      {
        title: 'estabelecimento',
        type: [5, 6],
        path: PATH_DASHBOARD.restaurant.edit,
        icon: <Icon icon="fa-solid:house-user" width="22" height="22" />
      },
      {
        title: 'Campanhas',
        path: PATH_DASHBOARD.campaigns.root,
        icon: <Icon icon="ant-design:notification-outlined" width="22" height="22" />,
        type: [5, 6],
        children: [{ title: 'participar', path: PATH_DASHBOARD.campaigns.participe }]
      },
      {
        title: 'horários',
        type: [5, 6],
        path: PATH_DASHBOARD.restaurant.opening,
        icon: <Icon icon="bxs:time" width="22" height="22" />
      },
      {
        title: 'áreas de entrega',
        type: [1, 2],
        path: PATH_DASHBOARD.deliveryFee.list,
        icon: <Icon icon="gis:road-map" width="22" />
      },
      {
        title: 'taxas de entrega',
        type: [5, 6],
        path: PATH_DASHBOARD.deliveryFee.list,
        icon: <Icon icon="emojione-monotone:delivery-truck" width="22" />
      },
      {
        title: 'mapa de calor',
        type: [1, 2, 3, 4, 5, 6],
        path: PATH_DASHBOARD.heatmap.root,
        icon: <Icon icon="akar-icons:fire" width="22" />,
        info: <Label color="primary">BETA</Label>
      },
      {
        title: 'avaliações',
        type: [5, 6],
        shop: false,
        path: PATH_DASHBOARD.restaurant.rating,
        icon: <Icon icon="ant-design:star-filled" width="22" />
      },
      {
        title: 'entregadores',
        type: [3, 4],
        path: PATH_DASHBOARD.deliveryMan.list,
        icon: <Icon icon="ri:motorbike-fill" width="22" />
      },
      {
        title: 'Histórico',
        type: [1, 2, 3, 4, 5, 6],
        path: PATH_DASHBOARD.log.list,
        icon: <Icon icon="icon-park-outline:log" width="22" />
      },
      {
        title: 'Conteúdos',
        type: [3, 4, 5, 6],
        path: PATH_DASHBOARD.content.list,
        icon: <Icon icon="akar-icons:book" width="22" />
      },
      {
        title: 'Marketing',
        type: [3, 4, 5, 6],
        path: PATH_DASHBOARD.marketing.list,
        icon: <Icon icon="logos:google-marketing-platform" width="22" />
      },

      {
        title: 'Banners',
        type: [3, 4],
        path: PATH_DASHBOARD.banner.listLic,
        icon: <Icon icon="bi:card-image" width="22" />
      },
      /*
      {
        title: 'Importação',
        type: [3, 4],
        path: PATH_DASHBOARD.import.root,
        icon: <Icon icon="fe:import" width="22" />,
        info: <Label color="success">NOVO</Label>
      },
      */
      {
        title: 'Atendimentos',
        type: [3, 4],
        path: PATH_DASHBOARD.attendance.all,
        icon: <Icon icon="mdi:account-help" width="22" />,
        info: <Label color="success">NOVO</Label>
      },
      {
        title: 'grades',
        path: PATH_DASHBOARD.grid.root,
        type: [1, 2],
        icon: <Icon icon="mdi:grid" width="22" />,
        children: [
          { title: 'listar', path: PATH_DASHBOARD.grid.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.grid.new }
        ]
      }
    ]
  },
  {
    subheader: 'gerenciamento',
    items: [
      {
        title: 'relatórios',
        path: PATH_DASHBOARD.reports,
        icon: ICONS.user,
        type: [1, 2, 3, 5]
      },
      {
        title: 'financeiro',
        path: PATH_DASHBOARD.financial.distributor,
        icon: <Icon icon="arcticons:home-finance" width="22" />,
        info: <Label color="primary">NOVO</Label>,
        type: [1, 2, 3, 5],
        children: [
          { title: 'Faturamento', path: PATH_DASHBOARD.financial.distributor },
          { title: 'Cupons', path: PATH_DASHBOARD.financial.coupons },
        ]
      },
      {
        title: 'análises',
        path: PATH_DASHBOARD.request.list,
        icon: <Icon icon="hugeicons:analytics-up" width="22" height="22" />,
        type: [1, 2],
        children: [
          /* { title: 'abandono de carrinho', path: PATH_DASHBOARD.cart.abandonned }, */
          { title: 'ranking de licenciados', path: PATH_DASHBOARD.licensed.ranking },
        ]
      },
      {
        title: 'campanha',
        path: PATH_DASHBOARD.campaigns.root,
        icon: <Icon icon="ant-design:notification-outlined" width="22" height="22" />,
        type: [1, 2, 3, 4],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.campaigns.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.campaigns.new }
        ]
      },
      {
        title: 'licenciados',
        path: PATH_DASHBOARD.licensed.root,
        icon: ICONS.user,
        type: [1, 2],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.licensed.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.licensed.newUser },
          { title: 'permissões', path: PATH_DASHBOARD.licensed.permissions },
          { title: 'estabelecimentos', path: PATH_DASHBOARD.restaurant.list }
        ]
      },
      {
        title: 'colaboradores',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        type: [3, 5],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.user.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.user.new }
        ]
      },
      {
        title: 'categorias',
        path: PATH_DASHBOARD.category.root,
        icon: <Icon icon="ant-design:tag-filled" width="22" height="22" />,
        type: [1,2],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.category.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.category.newUser },
          { title: 'ordenar', path: PATH_DASHBOARD.category.order }
        ]
      },
      {
        title: 'marcas',
        path: PATH_DASHBOARD.brand.root,
        icon: <Icon icon="ic:baseline-branding-watermark" width="22" />,
        type: [1,2],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.brand.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.brand.new }
        ]
      },
      {
        title: 'estabelecimentos',
        path: PATH_DASHBOARD.restaurant.root,
        icon: <Icon icon="fa6-solid:house-laptop" width="22" />,
        type: [3],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.restaurant.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.restaurant.newUser }
        ]
      },
      {
        title: 'bairros',
        path: PATH_DASHBOARD.district.root,
        icon: <Icon icon="bi:map-fill" width="22" />,
        type: [3],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.district.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.district.new }
        ]
      },
      {
        title: 'categorias',
        path: PATH_DASHBOARD.productCategory.root,
        icon: <Icon icon="ant-design:tag-filled" width="22" height="22" />,
        type: [5, 6],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.productCategory.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.productCategory.new }
        ]
      },
      {
        title: 'produtos',
        path: PATH_DASHBOARD.product.root,
        icon: <Icon icon="mdi:food-outline" width="22" height="22" />,
        type: [1, 2, 5, 6],
        children: [
          { title: 'cardápio', path: PATH_DASHBOARD.product.list },
          { title: 'modelos', path: PATH_DASHBOARD.product.listModels },
        ]
      },
      {
        title: 'cupons',
        path: PATH_DASHBOARD.coupon.root,
        icon: <Icon icon="akar-icons:ticket" width="22" />,
        type: [1, 2, 3],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.coupon.list },
          { title: 'loja', path: PATH_DASHBOARD.coupon.shop },
          { title: 'cadastrar', path: PATH_DASHBOARD.coupon.new }
        ]
      },
      {
        title: 'fidelidades',
        path: PATH_DASHBOARD.fidelity.root,
        icon: <Icon icon="el:star-alt" width="22" />,
        type: [5, 6],
        shop: false,
        children: [
          { title: 'listar', path: PATH_DASHBOARD.fidelity.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.fidelity.new }
        ],
        info: <Label color="success">NOVO</Label>
      },
      {
        title: 'conteúdos',
        path: PATH_DASHBOARD.content.admin,
        icon: <Icon icon="akar-icons:book" width="22" />,
        type: [1, 2],
        children: [
          { title: 'categorias', path: PATH_DASHBOARD.contentCategory.list },
          { title: 'listar', path: PATH_DASHBOARD.content.admin },
          { title: 'cadastrar', path: PATH_DASHBOARD.content.new }
        ]
      },
      {
        title: 'marketing',
        path: PATH_DASHBOARD.marketing.admin,
        icon: <Icon icon="logos:google-marketing-platform" width="22" />,
        type: [1, 2],
        children: [
          { title: 'categorias', path: PATH_DASHBOARD.marketingCategory.list },
          { title: 'listar', path: PATH_DASHBOARD.marketing.admin },
          { title: 'cadastrar', path: PATH_DASHBOARD.marketing.new }
        ]
      },
      {
        title: 'contratos',
        path: PATH_DASHBOARD.contract.admin,
        icon: <Icon icon="icon-park-outline:log" width="22" />,
        type: [1, 2, 3, 4, 5, 6],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.contract.admin },
          { title: 'cadastrar', path: PATH_DASHBOARD.contract.new }
        ]
      },
      {
        title: 'push',
        path: PATH_DASHBOARD.push.root,
        icon: <Icon icon="ic:baseline-circle-notifications" width="22" />,
        type: [1, 3],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.push.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.push.new }
        ]
      },
      {
        title: 'banner',
        path: PATH_DASHBOARD.banner.root,
        icon: <Icon icon="bi:card-image" width="22" />,
        type: [1, 2],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.banner.list },
          { title: 'cadastrar', path: PATH_DASHBOARD.banner.new }
        ]
      },
      {
        title: 'atendimentos',
        path: PATH_DASHBOARD.attendance.root,
        icon: <Icon icon="mdi:account-help" width="22" />,
        type: [1, 2],
        children: [
          { title: 'listar', path: PATH_DASHBOARD.attendance.all },
          { title: 'cadastrar', path: PATH_DASHBOARD.attendance.new }
        ],
        info: <Label color="success">NOVO</Label>
      }
    ]
  }
];

export default sidebarConfig;
