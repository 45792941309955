import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contentCategories: [],
  contentCategoryList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'contentCategory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCategorysSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    deleteCategory(state, action) {
      const deleteCategory = filter(state.contentCategoryList, (category) => category._id !== action.payload);
      state.contentCategoryList = deleteCategory;
    },

    getCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.contentCategoryList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCategoryList(type = '') {
  let filter = '';
  if (type) filter = `?parent=${type}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/content-categories${filter}`);
      dispatch(slice.actions.getCategoryListSuccess(response.data.categories.docs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeCategory(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/content-categories`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateCategory(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/content-categories`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/content-categories/${newId}`);
      dispatch(slice.actions.deleteCategory(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
