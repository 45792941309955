import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  pushs: [],
  pushList: [],
  push: {},
  responsePost: null
};

const slice = createSlice({
  name: 'push',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPushsSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    getPushListSuccess(state, action) {
      state.isLoading = false;
      state.pushList = action.payload;
    },

    getPush(state, action) {
      state.isLoading = false;
      state.push = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getPushList(restaurant = '') {
  let filter = '';
  if (restaurant) filter = `?parent=${restaurant}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/push${filter}`);
      dispatch(slice.actions.getPushListSuccess(response.data.sms));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPush(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/push/${id}`);
      dispatch(slice.actions.getPush(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cancelPush(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${api}/push/${id}`);
      dispatch(slice.actions.getPush(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storePush(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/push`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
