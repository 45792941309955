import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    attendances: [],
    attendanceList: [],
    responsePost: null,
    attendance: {}
};

const slice = createSlice({
    name: 'attendance',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getAttendancesSuccess(state, action) {
            state.isLoading = false;
            state.attendances = action.payload;
        },

        deleteAttendance(state, action) {
            const deleteAttendance = filter(state.attendanceList, (attendance) => attendance._id !== action.payload);
            console.log(deleteAttendance);
            state.attendanceList = deleteAttendance;
        },

        getAttendanceListSuccess(state, action) {
            state.isLoading = false;
            state.attendanceList = action.payload;
        },

        getAttendance(state, action) {
            state.isLoading = false;
            state.attendance = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getAttendanceList(parent = '') {
    return async(dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${api}/attendances`);
            response.data.attendances.reverse()
            dispatch(slice.actions.getAttendanceListSuccess(response.data.attendances));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function storeAttendance(values) {
    return async(dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${api}/attendances`, values);
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error.response.data;
        }
    };
}

// ----------------------------------------------------------------------

export function updateAttendance(values) {
    return async(dispatch) => {
        // dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${api}/attendances`, values);
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error.response.data;
        }
    };
}

// ----------------------------------------------------------------------

export function deleteAttendance(newId) {
    return async(dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.put(`${api}/attendances`, { _id: newId, status: 'd' });
            dispatch(slice.actions.deleteAttendance(newId));
            return true;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error.response.data;
        }
    };
}

export function getAttendance(id) {
    return async(dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${api}/attendance/${id}`);
            dispatch(slice.actions.getAttendance(response.data.attendance));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}