import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useEffect, useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import Gleap from 'gleap';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks 
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import YayButton from '../../components/YayButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };




  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MenuItem
          key="Início"
          to="/"
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Icon style={{
            width: 24,
            marginRight: 15,
            height: 24
          }} icon="carbon:home" />
          Início
        </MenuItem>

        <MenuItem
          key="Minha conta"
          to={PATH_DASHBOARD.user.account}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Icon style={{
            width: 24,
            marginRight: 15,
            height: 24
          }} icon="material-symbols-light:account-circle-outline" />
          Minha conta
        </MenuItem>

        {
          user.type < 4 && (<><YayButton /><a href="https://universidade.jaodelivery.com/users/sign_in"
            target="_blank"
            rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem
              key="Universidade"
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Icon style={{
                width: 24,
                marginRight: 15,
                height: 24
              }} icon="material-symbols-light:school-outline" />
              Universidade
            </MenuItem>
          </a></>)
        }

        <MenuItem
          key="Central de Ajuda"
          onClick={(e) => {
            Gleap.openHelpCenter(false)
            handleClose();
          }}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Icon style={{
            width: 24,
            marginRight: 15,
            height: 24
          }} icon="ph:book-open-light" />
          Central de Ajuda
        </MenuItem>


        <a href="https://anydesk.com/pt/downloads/thank-you?dv=win_exe"
          target="_blank"
          rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem
            key="Suporte Remoto"
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Icon style={{
              width: 24,
              marginRight: 15,
              height: 24
            }} icon="ph:desktop-thin" />
            Suporte Remoto
          </MenuItem>
        </a>


        <a href="https://cadastro.jao.app/gestor-de-pedidos"
          target="_blank"
          rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem
            key="Sistema Desktop"
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Icon style={{
              width: 24,
              marginRight: 15,
              height: 24
            }} icon="material-symbols-light:system-update-alt-rounded" />
            Sistema Desktop
          </MenuItem>
        </a>


        <a href="https://play.google.com/store/apps/details?id=pedidos.jaodelivery&hl=en_US"
          target="_blank"
          rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem
            key="Aplicativo Lojista"
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Icon style={{
              width: 24,
              marginRight: 15,
              height: 24
            }} icon="uit:android-alt" />
            Aplicativo Lojista
          </MenuItem>
        </a>



        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Sair
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
