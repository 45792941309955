import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  config: {}
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getConfigsSuccess(state, action) {
      state.isLoading = false;
      state.config = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getConfig() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/config`);
      console.log(response.data.config);
      dispatch(slice.actions.getConfigsSuccess(response.data.config));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateConfig(values) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${api}/config`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
