import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  bannerList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getBannersSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    deleteBanner(state, action) {
      const deleteBanner = filter(state.bannerList, (category) => category._id !== action.payload);
      state.bannerList = deleteBanner;
    },

    getBannerListSuccess(state, action) {
      state.isLoading = false;
      state.bannerList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getBannerList(type = '') {
  let filter = '';
  if (type) filter = `?parent=${type}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/banners${filter}`);
      dispatch(slice.actions.getBannerListSuccess(response.data.banners));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBannerListLic(type = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/banners-lic`);
      dispatch(slice.actions.getBannerListSuccess(response.data.banners));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeBanner(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/banners`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateBanner(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/banners`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBanner(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/banners/${newId}`);
      dispatch(slice.actions.deleteBanner(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function changeCities(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${api}/banners-cities`, values);
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}


