import {
  Alert,
  Paper,
  Step,
  StepLabel,
  Stepper,
  alpha,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';

function OnlinePayStatus() {
  const { themeStretch } = useSettings();
  const { user } = useAuth()
  const userType = user.type
  const [activeStep, setActiveStep] = useState(2);
  const [skipped, setSkipped] = useState();
  const isStepOptional = (step) => step === 1;
  const isStepSkipped = (step) => skipped?.has(step);

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (user?.tunaStatus === '1') {
      setActiveStep(3);
    }
  }, [user?.tunaStatus]);

  const steps = ['Contrato assinado', 'Dados enviados', 'Aguardando aprovação', 'Conta aprovada.'];

  const shopActive = (user, type, status) => (
    <div>
      <Paper
        variant="outlined"
        sx={{
          p: 3,
          width: 1
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })
          }
        </Stepper>
        <>
          <Paper
            sx={{
              p: 3,
              my: 3,
              minHeight: 120,
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12)
            }}
          >
            {status === '0' && (
              <Alert severity="warning" sx={{ my: 1 }}>
                Sua conta está em análise, aguarde nosso retorno em até 5 dias úteis.
              </Alert>
            )}
            {status === '1' && (
              <Alert severity="success" sx={{ my: 1 }}>
                Sua conta foi aprovada! Bem-vindo ao nosso serviço.
              </Alert>
            )}
            {status !== '0' && status !== '1' && (
              <Alert severity="error" sx={{ my: 1 }}>
                Não foi possível aprovar sua solicitação nesse momento.
                <br />
                Entre em contato com o responsável por sua cidade.
              </Alert>
            )}
          </Paper>
        </>
      </Paper>
    </div>
  );


  return (
    <>
      <div>{shopActive(user.infoAsaas?.name, user.infoAsaas?.personType, user?.tunaStatus)}</div>
    </>);

}

export default OnlinePayStatus
