import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@material-ui/core';
import OnlinePayHome from '../pages/dashboard/Payments/OnlinePayHome';
import useAuth from '../hooks/useAuth';


// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};

const useCurrentRole = () => {
  const { user } = useAuth();
  return {
    canUseOnlinePayment: user.restaurant?.canUseOnlinePayment || false,
    currentRole: user.type,
    fields: user.fields
  };
};

export default function RoleBasedGuard({ accessibleRoles, field, children }) {
  const { currentRole, fields, canUseOnlinePayment } = useCurrentRole();
  const dontHadField = field && fields && !fields.includes(field);
  const dontHadOnlinePayment = currentRole === 5 && field === 'pagamentos' && !canUseOnlinePayment;
  if (accessibleRoles) {
    if (!accessibleRoles.includes(currentRole) || dontHadField || dontHadOnlinePayment) {
      return (
        dontHadOnlinePayment ? (
          <Container>
            <OnlinePayHome perm='true' />
          </Container>
        ) : (
          <Container>
            <Alert severity="error">
              <AlertTitle>Permissão negada</AlertTitle>
              Você não possui permissão para acessar essa página
            </Alert>
          </Container>
        )
      );

    }
  }

  return <>{children}</>;
}
