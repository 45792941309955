import { useSnackbar } from 'notistack5';
import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
import serialize from '../../utils/serialize';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  onlinePaymentsList: []
};

const slice = createSlice({
  name: 'onlinePayment',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getOnlinePaymentsListSuccess(state, action) {
      state.onlinePaymentsList = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export function getOnlinePaymentsList(userId, userType, page = 1, rows = 20, initialDate, endDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        userType === 5 || userType === 6 ?
          `${api}/tuna/restaurants/${userId}?initialDate=${initialDate}&endDate=${endDate}&page=${page}&rows=${rows}`
          : `${api}/tuna/licenseds/${userId}?initialDate=${initialDate}&endDate=${endDate}&page=${page}&rows=${rows}`
      );
      dispatch(slice.actions.getOnlinePaymentsListSuccess(response.data.transactions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
