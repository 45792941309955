import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { api } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  productCategories: [],
  productCategoryList: [],
  responsePost: null
};

const slice = createSlice({
  name: 'productCategory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCategorysSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    deleteCategory(state, action) {
      const deleteCategory = filter(state.productCategoryList, (category) => category._id !== action.payload);
      console.log(deleteCategory);
      state.productCategoryList = deleteCategory;
    },

    getCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.productCategoryList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCategoryList(restaurant = '') {
  let filter = '';
  if (restaurant) filter = `?parent=${restaurant}`;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${api}/product-categories${filter}`);
      dispatch(slice.actions.getCategoryListSuccess(response.data.categories.docs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function storeCategory(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${api}/product-categories`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateCategory(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/product-categories`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

export function updateCategoryOrder(values) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${api}/product-categories-order`, values);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(newId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${api}/product-categories/${newId}`);
      dispatch(slice.actions.deleteCategory(newId));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.response.data;
    }
  };
}
